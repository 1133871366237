import axios, { Axios, AxiosRequestConfig } from "axios";
import {
	ORGANIZATION_HTTP_CONFIG,
	OrganizationHttp,
} from "~/common/organization";

import { RequestMethod, fetchRequest } from "../utils/utils";

const { routes } = ORGANIZATION_HTTP_CONFIG;

/**
 * Currently, only used for middleware
 *
 * @param options for the client
 * @returns HTTP
 */
export function createOrganizationClientHttp(
	options: Pick<AxiosRequestConfig, "baseURL"> = {},
): OrganizationHttp {
	//  TODO: better (global axios?), but keep Server & frontend side separated
	const client = new Axios(
		axios.mergeConfig(axios.defaults as never, options),
	);

	return {
		findAndCount: query =>
			client
				.get(routes.findAndCount.path({}), { params: query })
				.then(({ data }) => data as never),
		findById: params =>
			client
				.get(routes.findById.path(params))
				.then(({ data }) => data as never),
	};
}

// TODO: re-use the ClientHttp
export const apiOrganizationEndpoints: OrganizationHttp = {
	findAndCount: query =>
		fetchRequest({
			endpoint: routes.findAndCount.path({}),
			method: RequestMethod.GET,
			query,
		}),
	findById: params =>
		fetchRequest({
			endpoint: routes.findById.path(params),
			method: RequestMethod.GET,
		}),
};
