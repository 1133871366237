import { styled } from "@mui/joy";
import { useTranslation } from "next-i18next";
import { JSX, useMemo } from "react";

import { LayoutModal, ModalProps } from "./LayoutModal";
import {
	Button,
	ButtonProps,
	ButtonVariants,
} from "../../../ui-form/components/Button/Button";

/**
 * Enum for the buttons that can be displayed in the confirm modal
 */
export enum ConfirmModalButton {
	BUTTON_BACK = "BUTTON_BACK",
	BUTTON_CUSTOM = "BUTTON_CUSTOM",
	BUTTON_DELETE = "BUTTON_DELETE",
	BUTTON_NO = "BUTTON_NO",
	BUTTON_YES = "BUTTON_YES",
}

export const ButtonMapping = {
	[ConfirmModalButton.BUTTON_CUSTOM]: { label: "modal.custom" },
	[ConfirmModalButton.BUTTON_YES]: {
		label: "common.button.validate",
		// startDecorator: "🔍",
	},
	[ConfirmModalButton.BUTTON_NO]: {
		label: "common.button.cancel",
		variant: ButtonVariants.SECONDARY,
	},
	[ConfirmModalButton.BUTTON_BACK]: {
		label: "common.button.back",
		variant: ButtonVariants.SECONDARY,
	},
	[ConfirmModalButton.BUTTON_DELETE]: {
		label: "common.button.delete",
		variant: ButtonVariants.DANGER,
	},
} as const satisfies Record<ConfirmModalButton, ButtonProps>;

/**
 * Props for the ConfirmModal component
 *
 * @param buttons The buttons to display in the modal
 * @param message The message to display in the modal
 * @param onClick The function to call when a button is clicked
 * @param title The title of the modal
 */
export interface ConfirmModalProps {
	buttons?: ConfirmModalButton[];
	message?: string;
	onClick?: (clickedButton?: ConfirmModalButton) => Promise<void> | void;
	onClose?: () => void;
	title?: string;
}

export const ConfirmModal = ({
	buttons,
	message,
	onClick,
	onClose,
	open,
	title,
}: ConfirmModalProps & Pick<ModalProps, "onClose" | "open">): JSX.Element => {
	const { t } = useTranslation("common");
	const finalButtons = useMemo(
		() =>
			buttons ?? [
				ConfirmModalButton.BUTTON_NO,
				ConfirmModalButton.BUTTON_YES,
			],
		[buttons],
	);

	const actions: ModalProps["actions"] = ({
		onClose: actionOnClose = () => void 0,
	}) => (
		<>
			{finalButtons.map(btn => {
				const btnBase = ButtonMapping[btn];

				return (
					<Button
						{...btnBase}
						key={btn}
						label={btnBase.label && t(btnBase.label)}
						onClick={() => {
							void onClick?.(btn);
							actionOnClose();
						}}
					/>
				);
			})}
		</>
	);

	const body: ModalProps["body"] = (
		<Body>
			{message?.split("\n").map(line => <p key={line}>{line}</p>)}
		</Body>
	);

	return (
		<LayoutModal
			actions={actions}
			body={body}
			onClose={onClose}
			open={open}
			title={title}
		/>
	);
};

const Body = styled("div")`
	line-height: 20px;
`;
