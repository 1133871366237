import { HttpRoute } from "@appti/core";

import type * as DTOs from "./dtos";
import { GenericOrganizerEmailDtoQuery } from "../../../auth";
import { FileQueryResultsDto } from "../../../file/dtos";
import type { APPLICATION_HTTP_CONFIG } from "../../application.http";

/**
 * Create routes definitions for `value` features
 *
 * @param base of the HttpRoute builder
 * @returns route definitions
 */
export function createValueHttpDefinitions<
	const BASE extends HttpRoute.BuilderAny,
>(base: BASE) {
	const viaInputsRoot = base.addSegment("inputs");
	const fileByInputRoot = viaInputsRoot
		.addSegment({
			param: "inputId",
			type: "param",
			validation: "number",
		})
		.addSegment("files");

	// This would make more sense `byValue`, but for now, the frontend, does not keep trace of the valueId
	const fileByInputById = fileByInputRoot.addSegment({
		param: "fileId",
		type: "param",
		validation: "string",
	});

	return {
		findAndCount: base.get<() => Promise<DTOs.ApplicationValueDto[]>>(),
		updateBatch:
			viaInputsRoot.patch<() => Promise<DTOs.ApplicationValueDto[]>>(),

		/** Append (or create) files to the value of a file-input */
		fileAppendByInput:
			fileByInputRoot.post<
				(
					body: DTOs.ApplicationValueCreateFileOptions,
					query: GenericOrganizerEmailDtoQuery,
				) => Promise<DTOs.ApplicationValueDto>
			>(),
		/** Delete the file of a value (and updates the value) */
		fileDeleteByInput:
			fileByInputById.delete<
				(
					query: GenericOrganizerEmailDtoQuery,
				) => Promise<DTOs.ApplicationValueDto>
			>(),
		/** List the files of a value by its input id */
		fileFindAndCountByInput:
			fileByInputRoot.get<
				(
					query: GenericOrganizerEmailDtoQuery,
				) => Promise<FileQueryResultsDto>
			>(),
		/** Get the file, ex: use the path as an attachment */
		fileLoadByInput: fileByInputById
			.addSegment("file")
			.get<(query: GenericOrganizerEmailDtoQuery) => Promise<void>>(),
	} as const satisfies HttpRoute.Definitions;
}

/** HTTP specification for the `Application Group Check` feature */
export type ApplicationValueHttp = HttpRoute.Handlers<
	typeof APPLICATION_HTTP_CONFIG.children.instances.children.values
>;
