import { styled } from "@mui/joy";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "next-i18next";
import { useState } from "react";
import {
	ApplicationInstanceModel,
	applicationInstanceProgressStepSchema,
	ApplicationInstanceStep,
} from "~/common/application/instance";

import { ApplicationInstanceRepository } from "../../../services/api/repositories/ApplicationInstanceRepository";
import { stepMapping } from "../../../utils/commons/stepsTranslationMaps";
import { Banner, BannerVariants } from "../../ui-atoms/components/Banner";
import { Icon, IconName } from "../../ui-atoms/components/Icons/Icon";
import { Button, ButtonVariants } from "../../ui-form/components/Button/Button";
import { Select } from "../../ui-form/components/Select";
import {
	LayoutModal,
	ModalProps,
} from "../../ui-layout/components/Modal/LayoutModal";
import { textSmall } from "../../ui-layout/styles/textStyles";
import {
	getUpdatedStepWarning,
	showUpdatedStepNotification,
} from "../services/updatedStepUtils";
import { useDecodedAppId } from "../services/useDecodedAppId";

type ApplicationUpdateStepModalProps = Pick<ModalProps, "onClose" | "open">;

/**
 * Modal to update the status of an application instance
 *
 * @param props The props
 * @param props.onClose The function to close the modal
 * @param props.open Whether the modal is open
 * @returns The component
 */
export const ApplicationUpdateStepModal = ({
	onClose,
	open,
}: ApplicationUpdateStepModalProps) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();

	const { applicationId, organizationId } = useDecodedAppId();

	const { data: instance } = useQuery({
		...ApplicationInstanceRepository.findOne(organizationId, applicationId),
	});

	const { isPending: isPendingUpdateStatus, mutate: mutateUpdateStatus } =
		useMutation({
			...ApplicationInstanceRepository.updateStatus(
				organizationId,
				applicationId,
			),
			meta: {
				onSuccess: async (instance: ApplicationInstanceModel) => {
					await queryClient.invalidateQueries({
						queryKey: ["instance"],
					});
					showUpdatedStepNotification(instance.step, t);
					onClose && onClose();
				},
			},
		});

	const [selectedStep, setSelectedStep] = useState<
		ApplicationInstanceStep | undefined
	>(instance?.step);

	// Important: when switching status through the modal,
	// we don't force-update the inputs values (like we do with the Validate button).
	const onValidateStatusClick = () => {
		if (!selectedStep) {
			return;
		}
		mutateUpdateStatus({ step: selectedStep });
	};

	const isValidateLoading = isPendingUpdateStatus;
	const isValidateDisabled = !selectedStep || selectedStep === instance?.step;
	const isCancelDisabled = isPendingUpdateStatus;

	const actions: ModalProps["actions"] = ({
		onClose: actionOnClose = () => void 0,
	}) => (
		<>
			<Button
				disabled={isCancelDisabled}
				label={t("common.button.cancel")}
				onClick={() => actionOnClose()}
				variant={ButtonVariants.SECONDARY}
			/>
			<Button
				disabled={isValidateDisabled}
				isLoading={isValidateLoading}
				label={t(
					"pages.application-details.modal-update-status.button-validate",
				)}
				onClick={onValidateStatusClick}
				startDecorator={<Icon name={IconName.checkmark} size={20} />}
			/>
		</>
	);

	const options = applicationInstanceProgressStepSchema.options.map(step => ({
		label: stepMapping(t)[step],
		value: step,
	}));
	const warning = getUpdatedStepWarning(instance?.step, selectedStep, t);

	const body = (
		<BodyContainer>
			<div>
				<SelectLabel>
					{t(
						"pages.application-details.modal-update-status.select-label",
					)}
				</SelectLabel>
				<Select
					onChange={value =>
						setSelectedStep(
							value.target.value as ApplicationInstanceStep,
						)
					}
					options={options}
					value={selectedStep}
				/>
			</div>

			{warning && (
				<Banner variant={BannerVariants.ERROR}>{warning}</Banner>
			)}
		</BodyContainer>
	);

	return (
		<LayoutModal
			actions={actions}
			body={body}
			data-testid="application-update-step-modal"
			onClose={onClose}
			open={open}
			title={t("pages.application-details.modal-update-status.title")}
		/>
	);
};

const BodyContainer = styled("div")`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const SelectLabel = styled("label")`
	${textSmall}
	margin-bottom: 4px;
`;
