import { GetServerSidePropsContext } from "next";
import qs from "qs";
import { ParsedUrlQuery } from "querystring";

import { FetchError } from "./FetchError";
import { ENVIRONMENT } from "../../../environment";

const backendConfig = ENVIRONMENT.backend();

const config = {
	devTools: false,
	urlApi: backendConfig.type === "url" ? backendConfig.url : "/api",
};

export { config as FETCH_CONFIG };

export type CtxType = GetServerSidePropsContext<ParsedUrlQuery>;

/**
 * Build and Fetch request
 *
 * @param param0 Props
 * @param param0.endpoint url
 * @param param0.body request body
 * @param param0.ctx context
 * @param param0.method request method
 * @param param0.params url entity id
 * @param param0.query url params
 * @param param0.responseFormatter Function to format data from back to front
 * @param param0.signal signal
 * @returns {Promise<FrontEntity>} response
 */
export const fetchRequest = <BackEntity, FrontEntity, BackBody = never>({
	body,
	endpoint,
	method,
	params,
	query,
	responseFormatter,
	signal,
}: {
	body?: BackBody;
	ctx?: CtxType;
	endpoint: string;
	method: RequestMethod;
	params?: object;
	query?: object;
	responseFormatter?: (data: BackEntity) => FrontEntity;
	signal?: AbortSignal;
}): Promise<FrontEntity> => {
	let url = endpoint;

	// Build params
	if (params) {
		url = Object.entries(params).reduce(
			(s, [v, k]: [string, string]) =>
				s.replace(new RegExp(`:${v}`, "gi"), k),
			endpoint,
		);
	}

	if (url[url.length - 1] !== "/") {
		url += "/";
	}

	// Build query
	if (query) {
		const queryString = qs.stringify(query, { encode: true });
		if (queryString) {
			url += `?${queryString}`;
		}
	}

	return callApi<FrontEntity, BackEntity, BackBody>({
		body,
		endpoint: url,
		method,
		responseFormatter,
		signal,
	});
};

/** Build and Fetch request */
export enum RequestMethod {
	DELETE = "DELETE",
	GET = "GET",
	PATCH = "PATCH",
	POST = "POST",
	PUT = "PUT",
}

/**
 * Construct, send request and format result
 *
 * @param param0 Props
 * @param param0.endpoint url
 * @param param0.body request body
 * @param param0.contentType request contentType
 * @param param0.method request method
 * @param param0.responseFormatter Function to format data from back to front
 * @param param0.signal signal
 * @param param0.token token for secured url
 * @returns {Promise<FrontEntity>} response
 */
export const callApi = async <FrontEntity, BackEntity, BackBody>({
	body,
	contentType = "application/json",
	endpoint,
	method,
	responseFormatter,
	signal,
	token,
}: {
	body?: BackBody;
	contentType?: string;
	endpoint: string;
	method: RequestMethod;
	responseFormatter?: (data: BackEntity) => FrontEntity;
	signal?: AbortSignal;
	token?: string;
}): Promise<FrontEntity> => {
	if (!endpoint) {
		throw new Error("endpoint is required");
	}
	if (!method) {
		throw new Error("method is required");
	}

	const url = `${config.urlApi}${endpoint}`;

	const headers = new Headers({
		Connection: "keep-alive",
		"Content-Type": contentType,
	});
	if (token) {
		headers.set("Authorization", `Bearer ${token}`);
	}

	const options: Parameters<typeof fetch>[1] = {
		cache: "no-cache",
		credentials: "include",
		headers,
		method,
		mode: "cors",
		redirect: "follow",
		referrerPolicy: "no-referrer",
	};

	if (body) {
		options.body = JSON.stringify(body);
	}
	if (signal) {
		options.signal = signal;
	}

	if (config.devTools) {
		/* eslint-disable no-console -- log for dev only */
		console.log(
			"---------------- Fetch ------------------------------------------------------",
		);
		console.log(url, options);
		console.log(
			"-----------------------------------------------------------------------------",
		);
		/* eslint-enable no-console */
	}

	const response = await fetch(url, options);

	if (!response.ok) {
		throw new FetchError(response);
	}

	if (response.status === 204) {
		return null as FrontEntity;
	}

	return responseFormatter
		? responseFormatter((await response.json()) as BackEntity)
		: (response.json() as FrontEntity);
};
