import axios, { AxiosRequestConfig } from "axios";
import { APPLICATION_HTTP_CONFIG } from "~/common/application";
import { ApplicationValueHttp } from "~/common/application/instance/value";
import {
	ApplicationValueCreateFileOptions,
	ApplicationValueDto,
} from "~/common/application/instance/value/dtos";
import { GenericOrganizerEmailDtoQuery } from "~/common/auth";
import { FILE_CREATE_FORMDATA_PROPERTY } from "~/common/file";

import { FETCH_CONFIG, RequestMethod, fetchRequest } from "../utils/utils";

const {
	fileAppendByInput,
	fileDeleteByInput,
	fileFindAndCountByInput,
	fileLoadByInput,
} = APPLICATION_HTTP_CONFIG.children.instances.children.values;
const fileFindAndCountRequest: ApplicationValueHttp["fileFindAndCountByInput"] =
	(params, query) =>
		fetchRequest({
			endpoint: fileFindAndCountByInput.path(params),
			method: RequestMethod.GET,
			query,
		});
const fileDeleteRequest: ApplicationValueHttp["fileDeleteByInput"] = (
	params,
	query,
) =>
	fetchRequest({
		endpoint: fileDeleteByInput.path(params),
		method: RequestMethod.DELETE,
		query,
	});

const fileAppendByInputRequest = ((
	params,
	body: ApplicationValueCreateFileOptions,
	query,
	files: readonly File[] = [],
	options?: AxiosRequestConfig,
) => {
	const formData = new FormData();
	formData.set(
		"replace" satisfies keyof typeof body,
		body.replace ? "true" : "false",
	);
	for (const file of files) {
		formData.append(FILE_CREATE_FORMDATA_PROPERTY, file, file.name);
	}

	return axios
		.post<ApplicationValueDto>(
			FETCH_CONFIG.urlApi + fileAppendByInput.path(params),
			formData,
			{ ...options, params: query },
		)
		.then(({ data }) => data);
}) satisfies ApplicationValueHttp["fileAppendByInput"];

export const apiApplicationInstanceValueEndpoints = {
	fileAppendByInputRequest,
	fileDeleteRequest,
	fileFindAndCountRequest,
};

export type ApplicationValueInputAndFileRouteParams = Parameters<
	ApplicationValueHttp["fileDeleteByInput"]
>[0];
/**
 * Gets the path to the file of a value by its input
 *
 * @param params for the route
 * @param query for auth
 * @returns a path
 */
export function getApplicationValueFilePathByInput(
	params: ApplicationValueInputAndFileRouteParams,
	query: GenericOrganizerEmailDtoQuery = {},
) {
	const base = FETCH_CONFIG.urlApi + fileLoadByInput.path(params);

	return query.email
		? `${base}?${"email" satisfies keyof typeof query}=${query.email}`
		: base;
}
