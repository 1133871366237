import { styled } from "@mui/joy";
import Link from "next/link";
import { useParams } from "next/navigation";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { useContext } from "react";
import { RoleUtils } from "~/common/organization/user/role";

import { OrganizationLogo } from "./Logo";
import { UserMenu } from "./UserMenu";
import { ROUTES } from "../../../services/routes";
import { Icon, IconName } from "../../ui-atoms/components/Icons/Icon";
import { ButtonNav } from "../../ui-form/components/Button/ButtonNav";
import { UserContext } from "../templates/LayoutLoggedIn";

export const Header = (): JSX.Element => {
	const router = useRouter();
	const { organizationId } = useParams<{ organizationId: string }>();
	const { t } = useTranslation();
	const userContext = useContext(UserContext);

	const isAdmin = RoleUtils.isAdminInOrganization(
		userContext?.current?.user.roles ?? [],
		+organizationId,
	);

	return (
		<HeaderContainer>
			<LogoContainer>
				<Link href={ROUTES.home.url()}>
					<OrganizationLogo />
				</Link>
			</LogoContainer>

			<NavContainer>
				<ButtonNav
					active={ROUTES.applications.root.isActive(router.asPath)}
					href={ROUTES.applications.root.url()}
					label={t("menu.demandes")}
					startDecorator={<Icon name={IconName.workspace} />}
				/>
				{isAdmin && (
					<ButtonNav
						active={ROUTES.users.root.isActive(router.asPath)}
						href={ROUTES.users.root.url()}
						label={t("menu.utilisateurs")}
						startDecorator={<Icon name={IconName.userMultiple} />}
					/>
				)}
			</NavContainer>

			{<UserMenu />}
		</HeaderContainer>
	);
};

const HeaderContainer = styled("header")`
	align-items: center;
	display: flex;

	height: var(--height-header);
	padding: 0 48px;
`;

const LogoContainer = styled("div")`
	margin-right: 64px;
`;

const NavContainer = styled("nav")`
	margin-right: auto;
	display: flex;
	gap: 16px;
`;
