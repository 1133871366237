import type * as Multer from "multer";

/** Project configuration for the project */
export const FILE_CONFIGURATION = {
	/** Limits when uploading files */
	limits: {
		// These values (especially fileSize) could be set from the backend ENV,
		// 	and transmitted to front
		fileSize: 50 * 2 ** 20, // 50MB
		files: 10,
	} satisfies Required<
		Pick<NonNullable<Multer.Options["limits"]>, "fileSize" | "files">
	>,
};
