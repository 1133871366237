import * as z from "zod";

import { Environment, EnvironmentServerBackend } from "./environment.interface";

/**
 * Possible override from env shell (local).
 *
 * For other environments, prefer using a custom `environment.<env>.ts` file.
 * Even if it re-uses this content.
 */
export interface EnvironmentShellDefault {
	// TODO
	// /** [client-side] Override backend mode */
	// readonly FE_BACKEND_MODE?: string;
	// /** [client-side] Used with backend_mode */
	// readonly FE_BACKEND_URL?: string;

	/* ---- [server-side] ---- */

	/**  to define the UTL to the backend */
	readonly FE_SERVER_BACKEND_URL?: string;

	/** [server-side] override time */
	readonly FE_SERVER_MIDDLEWARE_HOST_VALIDITY?: string;
}

function getEnvServerBackend(): EnvironmentServerBackend {
	const env = process.env as EnvironmentShellDefault;

	if (!env.FE_SERVER_BACKEND_URL) {
		return { type: "auto" };
	}

	const url = z.string().url().parse(env.FE_SERVER_BACKEND_URL);
	return { type: "url", url };
}

/**
 * Loads from ENV the configuration for a "server-side" env
 *
 * @returns the ENV configuration
 */
export const getEnvServer: Environment["server"] = () => {
	const env = process.env as EnvironmentShellDefault;

	return {
		backend: getEnvServerBackend(),
		middleware: {
			hostOrganizationRedirection: {
				validityTime: z.coerce
					.number()
					.min(1)
					.default(5 * 60)
					.parse(env.FE_SERVER_MIDDLEWARE_HOST_VALIDITY),
			},
		},
	};
};
