import {
	APPLICATION_HTTP_CONFIG,
	ApplicationInstancesHttp,
} from "~/common/application";
import {
	ApplicationInstanceCountedByStatuses,
	ApplicationInstanceCountedByStatusesQuery,
	ApplicationInstanceCreateGroupCheckDto,
	ApplicationInstanceDto,
	ApplicationInstanceQueryDto,
	ApplicationInstanceQueryResultsDto,
	ApplicationInstanceUpdateStatusDto,
} from "~/common/application/instance/dtos";
import { ApplicationInstanceCreateDto } from "~/common/application/instance/dtos";
import { ApplicationGroupCheckDto } from "~/common/application/instance/group-check/dtos";
import {
	ApplicationValueDto,
	ApplicationValueSimpleUpdateDto,
	WithUpdateInputError,
} from "~/common/application/instance/value/dtos";
import { GenericOrganizerEmailDtoQuery } from "~/common/auth";

import { RequestMethod, fetchRequest } from "../utils/utils";

const { instances: route } = APPLICATION_HTTP_CONFIG.children;
const valueRoutes = route.children.values;

const updateRequest: ApplicationInstancesHttp["update"] = (params, body) =>
	fetchRequest({
		body,
		endpoint: route.update.path(params),
		method: RequestMethod.PATCH,
	});

export const apiApplicationInstanceEndpoints = {
	findAndCount: (
		orgId: number,
		query: ApplicationInstanceQueryDto,
	): Promise<ApplicationInstanceQueryResultsDto> =>
		fetchRequest({
			endpoint: route.findAndCount.path({ orgId }),
			method: RequestMethod.GET,
			query,
		}),
	findOne: (
		orgId: number,
		instanceId: ApplicationInstanceDto["_id"],
		query: GenericOrganizerEmailDtoQuery = {},
	): Promise<ApplicationInstanceDto> =>
		fetchRequest({
			endpoint: route.findOne.path({
				instanceId,
				orgId,
			}),
			method: RequestMethod.GET,
			query,
		}),

	countByStatus: (
		orgId: number,
		query: ApplicationInstanceCountedByStatusesQuery = {},
	): Promise<ApplicationInstanceCountedByStatuses> =>
		fetchRequest({
			endpoint: route.countByStatuses.path({ orgId }),
			method: RequestMethod.GET,
			query,
		}),

	create: (
		orgId: number,
		body: ApplicationInstanceCreateDto,
	): Promise<ApplicationInstanceDto> =>
		fetchRequest({
			body,
			endpoint: route.create.path({ orgId }),
			method: RequestMethod.POST,
		}),
	update: updateRequest,

	groupChecks: {
		create: (
			orgId: number,
			instanceId: ApplicationInstanceDto["_id"],
			body: ApplicationInstanceCreateGroupCheckDto,
		): Promise<ApplicationGroupCheckDto | undefined> =>
			fetchRequest({
				body,
				endpoint: route.children.groupChecks.create.path({
					instanceId,
					orgId,
				}),
				method: RequestMethod.POST,
			}),
		findAndCount: (
			orgId: number,
			instanceId: ApplicationInstanceDto["_id"],
		): Promise<ApplicationGroupCheckDto[]> =>
			fetchRequest({
				endpoint: route.children.groupChecks.findAndCount.path({
					instanceId,
					orgId,
				}),
				method: RequestMethod.GET,
			}),
	},
	updateStatus: (
		orgId: number,
		instanceId: ApplicationInstanceDto["_id"],
		body: ApplicationInstanceUpdateStatusDto,
		query: GenericOrganizerEmailDtoQuery = {},
	): Promise<ApplicationInstanceDto> =>
		fetchRequest({
			body,
			endpoint: route.updateStatus.path({
				instanceId,
				orgId,
			}),
			method: RequestMethod.PATCH,
			query,
		}),
	validate: (orgId: number, instanceId: number): Promise<void> =>
		fetchRequest({
			endpoint: route.validate.path({ instanceId, orgId }),
			method: RequestMethod.POST,
		}),

	getInputValues: (
		orgId: number,
		instanceId: ApplicationInstanceDto["_id"],
		query: GenericOrganizerEmailDtoQuery = {},
	): Promise<ApplicationValueDto[]> =>
		fetchRequest({
			endpoint: valueRoutes.findAndCount.path({
				instanceId,
				orgId,
			}),
			method: RequestMethod.GET,
			query,
		}),
	updateBatch: (
		orgId: number,
		instanceId: number,
		body: ApplicationValueSimpleUpdateDto[],
		query: GenericOrganizerEmailDtoQuery = {},
	): Promise<WithUpdateInputError<ApplicationValueDto>> =>
		fetchRequest({
			body,
			endpoint: valueRoutes.updateBatch.path({ instanceId, orgId }),
			method: RequestMethod.PATCH,
			query,
		}),
};
