import { CssVarsProvider } from "@mui/joy";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { AppProps } from "next/app";
import { Inter } from "next/font/google";
import { useRouter } from "next/navigation";
import { appWithTranslation, useTranslation } from "next-i18next";
import { useState } from "react";
import { ToastContainer } from "react-toastify";
import { z } from "zod";

import "react-toastify/dist/ReactToastify.css";

import nextI18nextConfig from "../../next-i18next.config";
import { customErrorMap } from "../features/ui-form/services/customErrorMap";
import { ModalProvider } from "../features/ui-layout/components/Modal/ModalProvider";
import { theme } from "../features/ui-layout/styles/theme";
import "../features/ui-layout/styles/global.css";
import { LayoutLoggedIn } from "../features/ui-layout/templates/LayoutLoggedIn";
import { ReactQueryConfig } from "../services/api/reactQueryConfig";
import { NextPageWithLayout } from "../utils/types/NextPageWithLayout";

type AppPropsWithLayout = AppProps & {
	Component: NextPageWithLayout;
};

// if (config.rollbar.active) {
// 	require("@services/rollbar");
// }
const inter = Inter({ subsets: ["latin"] });

z.setErrorMap(customErrorMap); // FIXME

const MyApp = ({ Component, ...pageProps }: AppPropsWithLayout) => {
	// This helps React notice when the same layout is reused across pages
	// see: https://nextjs.org/docs/basic-features/layouts
	const getLayout =
		Component.getLayout ??
		(page => <LayoutLoggedIn>{page}</LayoutLoggedIn>);

	const router = useRouter();
	const { t } = useTranslation();
	const [queryClient] = useState(() => ReactQueryConfig(router, t));

	return (
		<div className={inter.className}>
			<QueryClientProvider client={queryClient}>
				<CssVarsProvider theme={theme}>
					<ModalProvider>
						{getLayout(<Component {...pageProps} />)}
						<ToastContainer />
					</ModalProvider>
				</CssVarsProvider>

				{/*
				Ignored on non-dev builds:
				https://tanstack.com/query/v4/docs/framework/react/devtools#install-and-import-the-devtools

				FIXME: something better, ignored but still included in bundled app
				*/}
				<ReactQueryDevtools />
			</QueryClientProvider>
		</div>
	);
};

export default appWithTranslation(MyApp, nextI18nextConfig);
