import { extendTheme } from "@mui/joy";

export const theme = extendTheme({
	colorSchemes: {
		light: {
			palette: {
				blue: {
					2: "#ECEFFE",
					3: "#4242D7",
					primary: "#5051F9",
				},
				green: { 1: "#D3F3E7", 2: "#39654B", 3: "#40B58A" },
				grey: {
					1: "#F6F7F8",
					2: "#E2E5E9",
					3: "#B8BDC7",
					4: "#6E7A8F",
					text: "#212631",
				},
				orange: { 1: "#FEECCE", 2: "#A2711F" },
				red: { 1: "#E76363", 2: "#FCE8E8", 3: "#B81414" },
				white: {
					white: "#FFFFFF",
				},
			},
		},
	},
	components: {
		JoyModalDialog: {
			styleOverrides: {
				root: {
					"--Card-padding": "24px",
				},
			},
		},
	},
	typography: {
		h1: {
			fontSize: "2.25rem",
			fontWeight: 500,
		},
	},
});

declare module "@mui/joy/styles" {
	interface Palette {
		blue: {
			2: string;
			3: string;
			primary: string;
		};
		green: {
			1: string;
			2: string;
			3: string;
		};
		grey: {
			1: string;
			2: string;
			3: string;
			4: string;
			text: string;
		};
		orange: {
			1: string;
			2: string;
		};
		red: {
			1: string;
			2: string;
			3: string;
		};
		white: { white: string };
	}
}
