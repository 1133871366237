import { DefaultError, UseQueryOptions } from "@tanstack/react-query";
import { OrganizationHttp } from "~/common/organization";

import { apiEndpoints } from "../endpoints";

export const OrganizationRepository = {
	findById: (params => ({
		queryFn: () => apiEndpoints.organization.findById(params),
		queryKey: ["organizations", params.orgId],
	})) satisfies (
		...params: Parameters<OrganizationHttp["findById"]>
	) => UseQueryOptions<
		ReturnType<OrganizationHttp["findById"]>,
		DefaultError
	>,
};
