import { HttpRoute } from "@appti/core";

import type * as DTOs from "./dtos";
import { createGroupCheckHttpDefinitions } from "./group-check/group-check.http";
import { createValueHttpDefinitions } from "./value/value.http";

/**
 * Create routes definitions for `instance` features
 *
 * @param base of the HttpRoute builder
 * @returns route definitions
 */
export function createInstanceHttpDefinitions<
	const BASE extends HttpRoute.BuilderAny,
>(base: BASE) {
	const pathById = base.addSegment({
		param: "instanceId",
		type: "param",
		validation: "number",
	});

	return {
		children: {
			// (not matters the permissions)
			groupChecks: createGroupCheckHttpDefinitions(
				pathById.addSegment("group-check"),
			),
			values: createValueHttpDefinitions(pathById.addSegment("values")),
		},
		findAndCount:
			base.get<
				(
					query: DTOs.ApplicationInstanceCountedByStatusesQuery,
				) => Promise<DTOs.ApplicationInstanceQueryResultsDto>
			>(),
		findOne: pathById.get<() => Promise<DTOs.ApplicationInstanceDto>>(),

		create: base.post<
			(
				body: DTOs.ApplicationInstanceCreateDto,
			) => Promise<DTOs.ApplicationInstanceDto>
		>(),
		update: pathById.patch<
			(
				body: DTOs.ApplicationInstanceUpdateDto,
			) => Promise<DTOs.ApplicationInstanceDto>
		>(),

		/** Retrieve the total of instance for each statuses */
		countByStatuses: base
			// This can be removed and by done N (statuses) requests
			.addSegment("count-statuses")
			.get<
				(
					query: DTOs.ApplicationInstanceQueryDto,
				) => Promise<DTOs.ApplicationInstanceCountedByStatuses>
			>(),
		updateStatus: pathById
			.addSegment({ path: "status", type: "path" })
			.patch<() => Promise<DTOs.ApplicationInstanceDto>>(),
		validate: pathById
			.addSegment({ path: "validate", type: "path" })
			.post<() => Promise<void>>(),
	} as const;
}
