import { getEnvServer } from "./environment.defaults";
import { Environment } from "./environment.interface";

/** The prod "environment" */
export const ENVIRONMENT: Environment = {
	// TODO: from `window.location` ?
	// We expect, on production environments, the backend to be directly accessible
	//	It reduces the load on the Next server
	backend: () => ({ type: "url", url: "/api" }),
	queryLimitAll: 9999,
	server: getEnvServer,
};
