import { ModelString } from "@appti/core";
import * as z from "zod";

/** Validation schema for {@link FileModel} */
export const fileModelSchema = ModelString.schema.extend({
	mimetype: z.string().readonly(),
	name: z.string().describe("Filename"),
	size: z.number().readonly().describe("Size of the file"),
});
export type FileModel = z.infer<typeof fileModelSchema>;

/** The name of the property on which the file(s) are set */
export const FILE_CREATE_FORMDATA_PROPERTY = "files";

/** Validation schema for {@link FileCreate} */
export const fileCreateSchema = fileModelSchema
	// The rest of the data is determined by the file
	.pick({ name: true })
	// Name can be extracted from the buffer file
	.partial();
/** The content for a file creation (without the file) */
export type FileCreate = z.infer<typeof fileCreateSchema>;

/** Validation schema for {@link FileUpdate} */
export const fileUpdateSchema = fileCreateSchema;
export type FileUpdate = z.infer<typeof fileUpdateSchema>;
