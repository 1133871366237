import { apiApplicationInstanceEndpoints } from "./apiApplicationInstanceEndpoints";
import { apiApplicationInstanceValueEndpoints } from "./apiApplicationInstanceValueEndpoints";
import { apiApplicationTemplateEndpoints } from "./apiApplicationTemplateEndpoints";
import { apiApplicationVersionEndpoints } from "./apiApplicationVersionEndpoints";
import { apiAuthEndpoints } from "./apiAuthEndpoints";
import { apiOrganizationEndpoints } from "./apiOrganizationEndpoints";
import { apiOrganizationGroupEndpoints } from "./apiOrganizationGroupEndpoints";
import { apiUserOrganizationEndpoints } from "./apiOrganizationUserEndpoints";

export const apiEndpoints = {
	applicationInstanceValues: apiApplicationInstanceValueEndpoints,
	applicationInstances: apiApplicationInstanceEndpoints,
	applicationTemplates: apiApplicationTemplateEndpoints,
	applicationVersion: apiApplicationVersionEndpoints,
	auth: apiAuthEndpoints,
	organization: apiOrganizationEndpoints,
	organizationGroup: apiOrganizationGroupEndpoints,
	organizationUser: apiUserOrganizationEndpoints,
};
