import { HttpRoute } from "@appti/core";

import type * as DTOs from "./dtos";
import { createGroupHttpDefinitions } from "./group";
import { createUserHttpDefinitions } from "./user";

/** Root path of the routes */
const root = HttpRoute.builder("organizations");

/** Base route for organizations with id parameter (e.g. Create, Update, Delete) */
export const organizationHttpRoute = root.addSegment({
	param: "orgId",
	type: "param",
	validation: "number",
});

/** HTTP configuration for the `Organization` feature */
export const ORGANIZATION_HTTP_CONFIG = {
	children: {
		/** Actions for groups on an organization */
		groups: createGroupHttpDefinitions(
			organizationHttpRoute.addSegment({ path: "groups", type: "path" }),
		),
		/** Actions for users on an organization */
		users: createUserHttpDefinitions(
			organizationHttpRoute.addSegment({ path: "users", type: "path" }),
		),
	},
	routes: {
		// No 'CREATE' (from the API)

		/** Find & count some {@link OrganizationDto organizations} */
		findAndCount:
			root.get<
				(
					query: DTOs.OrganizationQueryDto,
				) => Promise<DTOs.OrganizationQueryResultsDto>
			>(),
		/** Find a {@link OrganizationDto} by its id */
		findById:
			organizationHttpRoute.get<() => Promise<DTOs.OrganizationDto>>(),
		/** Update an {@link OrganizationDto} */
		// update: organizationHttpRoute.patch<
		// 	(body: OrganizationUpdateDto) => Promise<OrganizationDto>
		// >(),
	} satisfies HttpRoute.Definitions,
} as const;

/** HTTP specification for the `Organization` feature */
export type OrganizationHttp = HttpRoute.Handlers<
	typeof ORGANIZATION_HTTP_CONFIG.routes
>;

/** HTTP specification for user by `Organization` feature */
export type UserOrganizationHttp = HttpRoute.Handlers<
	typeof ORGANIZATION_HTTP_CONFIG.children.users
>;

/** HTTP specification for `Group` feature */
export type GroupHttp = HttpRoute.Handlers<
	typeof ORGANIZATION_HTTP_CONFIG.children.groups
>;
