import { Model } from "@appti/core";
import * as z from "zod";

/** Schema for an organization (commune) */
export const organizationModelSchema = Model.schema.extend({
	accessDomain: z
		.string()
		.min(2)
		.nullable()
		.describe(
			"The domain used to access the application for this organization (!= official website of the organization)." +
				"\nExample: 'my-form.host.local'",
		),
	logoUrl: z
		.string()
		.url()
		.nullable()
		.describe("The URL of the logo to show"),
	name: z.string().min(3).describe("Name of the organization"),

	// TODO: web site, address, ...
});
export type OrganizationModel = z.infer<typeof organizationModelSchema>;
