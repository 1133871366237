import { styled } from "@mui/joy";
import { useQuery } from "@tanstack/react-query";
import Image from "next/image";
import { useParams } from "next/navigation";
import { useTranslation } from "next-i18next";
import { useMemo } from "react";

import LogoGeneric from "../../../../public/staticfront/images/logo-generic.png";
import { OrganizationRepository } from "../../../services/api/repositories/OrganizationRepository";

type LogoSize = "small" | "medium";

interface LogoProps {
	size?: LogoSize;
}

/**
 * Show the organizations logo currently showing (a generic one if none found).
 * It uses the organizationId from the router
 *
 * @param props for the component
 * @returns the node
 */
export function OrganizationLogo(props: LogoProps) {
	const { size = "small" } = props;

	const { t } = useTranslation();
	const { organizationId: paramId } =
		useParams<{ organizationId: string }>() ?? {};
	const organizationId = useMemo(() => {
		if (!paramId) {
			return false;
		}

		const id = +paramId;
		return Number.isInteger(id) ? id : false;
	}, [paramId]);

	const queryResult = useQuery({
		...OrganizationRepository.findById({ orgId: organizationId || 0 }),
		enabled: !!organizationId,
	});

	const logoUrl = useMemo(
		() =>
			queryResult.status === "success" ? queryResult.data.logoUrl : null,
		[queryResult],
	);

	if (!logoUrl) {
		return (
			<Image
				alt={t("common.logo.alt")}
				priority
				src={LogoGeneric}
				width={size === "small" ? "150" : "200"}
			/>
		);
	}

	return (
		<LogoContainer size={size}>
			{/* eslint-disable-next-line @next/next/no-img-element -- we use img because it's more adaptable to various layouts */}
			<img alt={t("common.logo.alt")} src={logoUrl} />
		</LogoContainer>
	);
}

const LogoContainer = styled("div")<{ size: LogoSize }>`
	height: ${({ size }) => (size === "small" ? "50px" : "80px")};
	width: ${({ size }) => (size === "small" ? "150px" : "200px")};

	display: flex;
	align-items: center;
	justify-content: center;

	img {
		max-height: 100%;
		max-width: 100%;
	}
`;
