// @ts-expect-error -- ignore
const path = require("path");

module.exports = {
	defaultLanguage: "fr",
	i18n: {
		defaultLocale: "fr",
		locales: ["fr"],
	},
	localePath: path.resolve("./public/staticfront/locales"),
	otherLanguages: ["fr"],
	reloadOnPrerender:
		process.env.NODE_ENV === "development" ||
		process.env.NX_TASK_TARGET_TARGET === "serve",
	serializeConfig: false,
};
