import { styled } from "@mui/joy";
import { ReactNode, JSX } from "react";

import { Icon, IconName } from "../../../ui-atoms/components/Icons/Icon";
import {
	Button,
	ButtonVariants,
} from "../../../ui-form/components/Button/Button";
import { textH1 } from "../../styles/textStyles";

/**
 * Props for the Modal component
 *
 * @param actions The actions to display in the modal
 * @param body The body of the modal
 * @param description The description of the modal
 * @param noCloseButton Whether to display the close button
 * @param onClose The function to call when the modal is closed
 * @param open Whether the modal is open
 * @param title The title of the modal
 * @returns The modal component
 */
export interface ModalProps {
	actions?: (props: { onClose?: () => void }) => ReactNode;
	body?: ReactNode;
	description?: string;
	noCloseButton?: boolean;
	onClose?: () => void;
	open?: boolean;
	title?: string;
}

/**
 * Note: For the modal to work as expected if you wrap it, you need to forward the onClose prop
 *
 * @param root0 The props
 * @param root0.open Whether the modal is open
 * @param root0.onClose The function to call when the modal is closed
 * @param root0.title The title of the modal
 * @param root0.description The description of the modal
 * @param root0.body The body of the modal
 * @param root0.actions The actions to display in the modal
 * @param root0.noCloseButton Whether to display the close button
 * @returns The modal component
 */
export const LayoutModal = ({
	actions,
	body,
	description,
	noCloseButton,
	onClose,
	open,
	title,
}: ModalProps): JSX.Element | null => {
	const isOpen = open ?? true;

	if (!isOpen) {
		return null;
	}

	return (
		<Modal>
			<ModalContainer>
				{!noCloseButton && (
					<ModalClose>
						<Button
							onClick={onClose}
							startDecorator={
								<Icon name={IconName.close} size={24} />
							}
							variant={ButtonVariants.TEXT}
						/>
					</ModalClose>
				)}
				{(title || description) && (
					<ModalTitle css={textH1}>{title}</ModalTitle>
				)}
				{body && <ModalBody>{body}</ModalBody>}
				{actions && <ModalActions>{actions({ onClose })}</ModalActions>}
			</ModalContainer>
			<Backdrop onClick={() => (onClose ? onClose() : null)} />
		</Modal>
	);
};

const Modal = styled("div")`
	bottom: 0;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: calc(var(--z-index-modal) - 1);
`;

const Backdrop = styled("div")`
	bottom: 0;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: -1;

	background: black;
	opacity: 0.4;
`;

const ModalContainer = styled("div")`
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: var(--z-index-modal);

	padding: 24px;
	width: var(--width-modal);

	display: flex;
	flex-direction: column;

	background: ${({ theme }) => theme.vars.palette.white.white};
	border-radius: 8px;
`;

const ModalClose = styled("div")`
	position: absolute;
	right: 24px;
	top: 26px;
`;

const ModalTitle = styled("h3")`
	margin-bottom: 24px;
`;

const ModalBody = styled("div")`
	margin-bottom: 64px;
`;

const ModalActions = styled("div")`
	display: flex;
	justify-content: space-between;
`;
