import * as z from "zod";

import type { Model as InputModel } from "./input.model";
import { Checkbox, Date, File, Number, Select, Text } from "./options";

export type Input = Omit<InputModel, "_id" | "createdAt" | "updatedAt">;

/** Schema option */
export interface SchemaValidationOptions {
	skipRequired?: boolean;
}

type SchemaValidation = {
	[K in InputModel["type"]]: {
		createSchema: (
			input: Extract<InputModel, { type: K }>,
			options: SchemaValidationOptions,
		) => z.ZodTypeAny;
		denormalize: (rawValue: string) => unknown;
	};
};
const schemaRecord = {
	checkbox: {
		createSchema: (input, options) =>
			Checkbox.createInputSchema(input, options),
		denormalize: value => Checkbox.denormalize(value),
	},
	date: {
		createSchema: (input, options) =>
			Date.createInputSchema(input, options),
		denormalize: value => Date.denormalize(value),
	},
	file: {
		createSchema: (input, options) =>
			File.createInputSchema(input, options),
		denormalize: value => File.denormalize(value),
	},
	number: {
		createSchema: (input, options) =>
			Number.createInputSchema(input, options),
		denormalize: value => Number.denormalize(value),
	},
	select: {
		createSchema: (input, options) =>
			Select.createInputSchema(input, options),
		denormalize: value => Select.denormalize(value),
	},
	text: {
		createSchema: (input, options) =>
			Text.createInputSchema(input, options),
		denormalize: value => Text.denormalize(value),
	},
} satisfies SchemaValidation;

/**
 * Generate a new zod schema depends on given input
 *
 * @param input configuration used to created zod schema
 *
 * @param options create input configuration
 * @throws UnprocessableEntityException
 * @returns a zod schema
 */
export function generateSchemaValidation<const T extends Input>(
	input: T,
	options: SchemaValidationOptions,
): ReturnType<(typeof schemaRecord)[T["type"]]["createSchema"]> {
	const handler = schemaRecord[input.type].createSchema;
	return handler(input as never, options) as never;
}

/**
 * denormalize input depends on given input
 *
 * @param type type of input
 * @param value raw value of input
 *
 * @returns denormalized value
 */
export function denormalizeValue<const T extends Input>(
	type: InputModel["type"],
	value: string,
): ReturnType<(typeof schemaRecord)[T["type"]]["denormalize"]> {
	return schemaRecord[type].denormalize(value as never) as never;
}

/**
 * normalize input to a string
 *
 * @param value value to normalize
 *
 * @returns normalized value
 */
export function normalizeValue(value: unknown): string {
	return JSON.stringify(value);
}

/**
 * Generate zod schema and denormalize input
 *
 * @param input type of input
 * @param value raw value of input
 * @param options create input configuration
 */
export function validate(
	input: InputModel,
	value: string,
	options: SchemaValidationOptions = {},
) {
	const zodSchema = generateSchemaValidation(input, options);

	// Actually can be an array of Date or string
	// TODO: Later think about return all errors at once instead one by one
	const values = denormalizeValue(input.type, value);

	zodSchema.parse(values);
}
