import { Input } from "../../application/element/input";
import { FileModel } from "../file.model";

/**
 * Checks if the file has a valid mimetype against a list of `accepts`.
 * An empty `accepts` is always valid
 *
 * @param file to check
 * @param accepts to verify against
 * @returns if the file has valid mimetype
 */
export function isValidMimeType(
	file: Pick<FileModel, "mimetype">,
	accepts: Input.Options.File.Model["accept"],
): boolean {
	if (accepts.length === 0) {
		return true;
	}

	const { mimetype } = file;
	return accepts.some(accept => mimetype.match(accept));
}
