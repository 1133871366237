import { Model } from "@appti/core";
import * as z from "zod";

import { Input } from "../input";

/** Validation schema for {@link Model} */
export const applicationProvisionModelSchema = Model.schema.extend({
	// TODO
	//	fkProvisions: Options.Provisions.schema.shape._id.describe(
	//		"FK of the 'Provisions section' this is linked to",
	//	),

	input: Input.Options.Number.schema,

	factor: z
		.number()
		.gt(0)
		.default(1)
		.nullable()
		.describe(
			"Factor to apply to the price. When `null`, it means that the value is absolute",
		),
	label: z.string().describe("Label for the provision line"),
	labelUnit: z
		.string()
		.describe("Label for the unit. e.g.: `$/hour` or `€/piece`"),
	negative: z
		.boolean()
		.default(false)
		.describe("Does the value only subtract the total?"),
	order: z
		.number()
		.describe("Order of this provision in the provisions section"),
	percent: z
		.boolean()
		.default(false)
		.describe("Is this a percent of a value? [NOT USED FOR NOW]"),
});

/**
 * A provision is a line with a "request"/"need"/... and its price-value (part of a 'provisions section').
 * Ex: number of tables ([factor: 5]): the input is 3 => 15
 */
export type ApplicationProvisionModel = z.infer<
	typeof applicationProvisionModelSchema
>;
