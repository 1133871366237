import * as z from "zod";

import { BaseModelOmit, createSchema, DenormalizeError } from "./base";
import { FileModel } from "../../../../file";
import { SchemaValidationOptions } from "../input.validation-schema";

/** The type of this input */
export const TYPE = "file";
/** Validation schema for {@link Model} */
export const schema = createSchema(TYPE).extend({
	accept: z
		.array(z.string())
		.describe(
			"File specifier for input file (without `,`)<br />" +
				"https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/accept#unique_file_type_specifiers" +
				"<br /> Empty means everything",
		),
	maxFiles: z
		.number()
		.min(1)
		.default(1)
		.describe("The maximum number of allowed files"),
});

/**
 * Input of type: file
 * This kind of input is a bit special as it does not uses the "regular" create/save methods =>
 * 	A custom handler is used
 */
export type Model = z.infer<typeof schema>;

/** The type of value from {@link Model} */
export type DenormalizedValue = Array<FileModel["_id"]>;

/**
 * Create a zod validation schema depends on given input
 *
 * @param input rules validation
 * @param options schema options
 * @returns schema generated by given input
 */
export function createInputSchema(
	input: BaseModelOmit<Model>,
	options: SchemaValidationOptions = { skipRequired: false },
) {
	const { maxFiles, required } = input;

	const base = z.array(z.string()).max(maxFiles);
	return !required || options.skipRequired ? base : base.min(1);
}

/**
 * Normalizes a value from this {@link Model}
 *
 * @param value to normalize
 * @returns normalized value
 */
export function normalize(value: DenormalizedValue) {
	return JSON.stringify(value);
}
/**
 * De-normalizes a value used this {@link Model}
 *
 * @param value value to denormalize
 * @returns de-normalized value
 *
 * @throws denormalization errors
 */
export function denormalize(value: string): DenormalizedValue {
	try {
		// TODO FILE_123: better
		return z.array(z.string()).parse(JSON.parse(value));
	} catch {
		throw new DenormalizeError(
			`Cannot normalize "${value}" to a string. Expected a string like "string".`,
		);
	}
}
